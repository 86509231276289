var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { lg: "12", color: "danger" } },
            [
              _c(
                "CRow",
                [
                  _c(
                    "CCol",
                    { attrs: { sm: "12" } },
                    [
                      _c(
                        "card-layout",
                        {
                          attrs: { cover: _vm.loading },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    [
                                      _c("CIcon", {
                                        attrs: { name: "cil-grid" }
                                      }),
                                      _vm._v(" Reports by Truck "),
                                      _c(
                                        "CRow",
                                        [
                                          _c("CCol", { attrs: { col: "2" } }, [
                                            _vm.branchInfo
                                              ? _c(
                                                  "div",
                                                  { staticClass: "p-0 m-0" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {},
                                                      [
                                                        _c("CAvatar", {
                                                          attrs: {
                                                            initials:
                                                              _vm.branchInfo
                                                                .metadata.code,
                                                            username:
                                                              _vm.branchInfo
                                                                .name
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "table-overflow-elipses"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.branchInfo.name
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]),
                                          _c(
                                            "CCol",
                                            {
                                              staticClass:
                                                "order-2 order-sm-2 order-md-1",
                                              attrs: {
                                                md: "8",
                                                lg: "8",
                                                xs: "12"
                                              }
                                            },
                                            [
                                              _c("CDatePicker", {
                                                attrs: {
                                                  "start.sync":
                                                    "datePicker.range.start",
                                                  "end.sync":
                                                    "datePicker.range.end",
                                                  "max-date":
                                                    _vm.datePicker.maxDate
                                                },
                                                on: {
                                                  update: _vm.handleRangeUpdated
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "CCol",
                                            {
                                              staticClass:
                                                "order-1 order-sm-1 order-md-2"
                                            },
                                            [
                                              _c(
                                                "CButtonGroup",
                                                {
                                                  staticClass:
                                                    "align-right float-right"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "px-1" },
                                                    [
                                                      _c(
                                                        "CButton",
                                                        {
                                                          staticClass:
                                                            "m-0 p-1",
                                                          attrs: {
                                                            color: "info",
                                                            square: "",
                                                            size: "sm"
                                                          },
                                                          on: {
                                                            click: _vm.refresh
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {},
                                                            [
                                                              _c("CIcon", {
                                                                staticClass:
                                                                  "h-100 m-0 p-0",
                                                                attrs: {
                                                                  name:
                                                                    "cil-sync"
                                                                }
                                                              }),
                                                              _c("span", {
                                                                staticClass:
                                                                  "color:white pull-right"
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("CRow")
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c("CDataTable", {
                            attrs: {
                              hover: "",
                              items: _vm.reportsByTruck,
                              fields: _vm.fields,
                              sorter: "",
                              dense: "",
                              "clickable-rows": "",
                              responsive: "",
                              noItemsView: {
                                noResults: "No results Available",
                                noItems: "No Reports to Display"
                              }
                            },
                            on: { "row-clicked": _vm.handleViewClick },
                            scopedSlots: _vm._u([
                              {
                                key: "truck",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "td",
                                      [
                                        _c("truck-info-card", {
                                          attrs: {
                                            device: item.device,
                                            name: item.truck,
                                            showStatus: false
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              },
                              {
                                key: "reports",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "h-100 widget-container align-middle"
                                      },
                                      [
                                        _c(
                                          "CRow",
                                          [
                                            _c(
                                              "CCol",
                                              {
                                                staticClass:
                                                  "align-middle mt-1 ml-4"
                                              },
                                              [
                                                _c("CWidgetStatsF", {
                                                  staticClass:
                                                    "day-summary-widget  ",
                                                  attrs: {
                                                    color: "light",
                                                    padding: false,
                                                    width_limit: true
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "icon",
                                                        fn: function() {
                                                          return [
                                                            _vm.useMedia
                                                              ? _c("CMedia", {
                                                                  staticClass:
                                                                    "p-0 m-0",
                                                                  attrs: {
                                                                    "aside-image-props": {
                                                                      src:
                                                                        "/Mapbox_dark_sample.jpg",
                                                                      height: 50,
                                                                      width: 88
                                                                    }
                                                                  }
                                                                })
                                                              : _c("CIcon", {
                                                                  staticStyle: {
                                                                    color:
                                                                      "var(--info)"
                                                                  },
                                                                  attrs: {
                                                                    name:
                                                                      "cil-location-pin",
                                                                    size: "xl"
                                                                  }
                                                                })
                                                          ]
                                                        },
                                                        proxy: true
                                                      },
                                                      {
                                                        key: "value",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "widget-value reports-text text-center",
                                                                class: [
                                                                  "fw-semibold text-info"
                                                                ]
                                                              },
                                                              [
                                                                _c("h5", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.reports
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      },
                                                      {
                                                        key: "title",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "text-medium-emphasis text-uppercase fw-semibold small text-center",
                                                                attrs: {
                                                                  id:
                                                                    "widget-title"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Job Reports "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              },
                              {
                                key: "first",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c("td", [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "font-weight": "bold" }
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.first.title) + " "
                                          )
                                        ]
                                      ),
                                      _c("h6", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.toLocalTime(
                                              item.first.timestamp
                                            )
                                          )
                                        )
                                      ])
                                    ])
                                  ]
                                }
                              },
                              {
                                key: "last",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "td",
                                      { staticClass: "h-100 align-middle" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-weight": "bold"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.last.title) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c("h6", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.toLocalTime(
                                                  item.last.timestamp
                                                )
                                              ) +
                                              " "
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                }
                              },
                              {
                                key: "link",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "td",
                                      {},
                                      [
                                        _c(
                                          "CButton",
                                          {
                                            attrs: {
                                              variant: "outline",
                                              color: "info"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.handleViewClick(item)
                                              }
                                            }
                                          },
                                          [_vm._v(" View Reports")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }