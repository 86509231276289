<template>
  <div>
    <CRow>
      <CCol lg="12" color="danger" class="">
        <CRow>
          <CCol sm="12">
            <card-layout :cover="loading">
              <template #header>
                <div>
                  <CIcon name="cil-grid" /> Reports by Truck
                  <CRow>
                    <CCol col="2">
                      <div class="p-0 m-0" v-if="branchInfo">
                        <span class="">
                          <CAvatar
                            :initials="branchInfo.metadata.code"
                            :username="branchInfo.name"
                          />
                        </span>
                        <span class="table-overflow-elipses">{{
                          branchInfo.name
                        }}</span>
                      </div>
                    </CCol>
                    <CCol
                      md="8"
                      lg="8"
                      xs="12"
                      class="order-2 order-sm-2 order-md-1"
                    >
                      <CDatePicker
                        start.sync="datePicker.range.start"
                        end.sync="datePicker.range.end"
                        :max-date="datePicker.maxDate"
                        @update="handleRangeUpdated"
                      />
                    </CCol>
                    <CCol class="order-1 order-sm-1 order-md-2">
                      <CButtonGroup class="align-right float-right">
                        <span class="px-1">
                          <CButton
                            color="info"
                            square
                            size="sm"
                            class="m-0 p-1"
                            @click="refresh"
                          >
                            <div style="">
                              <CIcon name="cil-sync" class="h-100 m-0 p-0" />
                              <span class="color:white pull-right"></span>
                            </div>
                          </CButton>
                        </span>
                      </CButtonGroup>
                    </CCol>
                  </CRow>
                  <CRow> </CRow>
                </div>
              </template>

              <CDataTable
                hover
                :items="reportsByTruck"
                :fields="fields"
                sorter
                dense
                clickable-rows
                responsive
                :noItemsView="{
                  noResults: 'No results Available',
                  noItems: 'No Reports to Display'
                }"
                @row-clicked="handleViewClick"
              >
                <template #truck="{ item }">
                  <td>
                    <truck-info-card
                      :device="item.device"
                      :name="item.truck"
                      :showStatus="false"
                    >
                    </truck-info-card>
                  </td>
                </template>
                <template #reports="{ item }">
                  <td class="h-100 widget-container align-middle">
                    <CRow>
                      <CCol class="align-middle mt-1 ml-4">
                        
                          <CWidgetStatsF
                          class="day-summary-widget  "
                          color="light"
                          :padding="false"
                          :width_limit="true"
                        >
                          <template #icon>
                            <CMedia
                              v-if="useMedia"
                              class="p-0 m-0"
                              :aside-image-props="{
                                src: '/Mapbox_dark_sample.jpg',
                                height: 50,
                                width: 88
                              }"
                            >
                            </CMedia>
                            <CIcon
                              v-else
                              name="cil-location-pin"
                              size="xl"
                              style="color:var(--info)"
                            />
                          </template>
                          <template #value>
                            <span
                              class="widget-value reports-text text-center"
                              :class="[`fw-semibold text-info`]"
                            >
                              <h5>{{ item.reports }}</h5>
                            </span>
                          </template>
                          <template #title>
                            <div
                              id="widget-title"
                              class="text-medium-emphasis text-uppercase fw-semibold small text-center"
                            >
                              Job Reports
                            </div>
                          </template>
                        </CWidgetStatsF>

                        
                      </CCol>
                    </CRow>
                  </td>
                </template>
                <template #first="{ item }">
                  <td>
                    <span style="font-weight: bold">
                      {{ item.first.title }}
                    </span>
                    <h6>{{ toLocalTime(item.first.timestamp) }}</h6>
                  </td>
                </template>
                <template #last="{ item }">
                  <td class="h-100 align-middle">
                    <span style="font-weight: bold">
                      {{ item.last.title }}
                    </span>
                    <h6>
                      {{ toLocalTime(item.last.timestamp) }}
                    </h6>
                  </td>
                </template>

                <template #link="{ item }">
                  <td class="">
                    <CButton
                      variant="outline"
                      color="info"
                      @click="handleViewClick(item)"
                    >
                      View Reports</CButton
                    >
                  </td>
                </template>
              </CDataTable>

              <!--ReportTable
                @show-report-click="showReport"
                class="rule-table"
                :usersData="reportData"
              /-->
            </card-layout>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>

<style scoped>
.rule-table {
  font-size: 15px;
}


.widget-container{
  min-width: 350px;
  text-align: center;
  
}
.reports-text {
  font-size: 16px;
}
</style>

<script>
import CDatePicker from "@/components/base/CDatePicker";
import CAvatar from "@/components/base/CAvatar";
import ThemeMixin from "@/mixins/ThemeMixin";
import CardLayout from "@/layouts/CardLayout.vue";

import TruckInfoCard from "@/views/app/TruckInfoCard.vue";
import CWidgetStatsF from "@/components/widgets/CWidgetStatsF.vue";

const DEFAULT_TIME_RANGE = 30 * (3600 * 24 * 1000);
export default {
  name: "DailyReportListView",
  components: {
    CDatePicker,
    CAvatar,
    CardLayout,
    TruckInfoCard,
    CWidgetStatsF
  },
  mixins: [ThemeMixin],
  data() {
    return {
      branchSelector: {
        selectedBranch: undefined
      },
      datePicker: {
        maxDate: Date.now(),
        range: {
          start: Date.now() - DEFAULT_TIME_RANGE,
          end: Date.now()
        }
      },
      useMedia: true,
      loading: false,
      branchInfo: undefined,
      selectedItem: {},
      activeTab: 0,
      reportData: [], // Store the RAW Report Data
      devices: [], // Store the Devices
      reportGroups: {},
      reportsByTruck: [],
      fields: [
        { key: "truck", label: "Truck",
        _style: `width:100px;`,},
        {
          key: "reports",
          label: "Total Reports",
          _style: `width:180px; text-align: center;`,
        },
        //{ key: "first", label: "First Report" },
        { key: "last", label: "Last Report",
        _style: `margin-left: 10px;`,
      },
        { key: "link", label: "Details" }
      ]
    };
  },
  computed: {
    /*
    branchInfo() {
      return this.$app.myBranch;
    },
    
    reportsByTruck() {
      // Map Report Groups into Elements
      let ret = [];
      for (var truck of this.devices) {
        if (this.reportGroups[truck.name]) {
          let count = this.reportGroups[truck.name].jobReports
            ? this.reportGroups[truck.name].jobReports.length
            : 0;
          if (count > 0) {
            let first = this.getFirst(this.reportGroups[truck.name].jobReports);
            let last = this.getLast(this.reportGroups[truck.name].jobReports);
            ret.push({
              truck: truck.name,
              device: truck.device,
              reports: count,
              _item: this.reportGroups[truck.name],
              first: first,
              last: last,
            });
          }
        }
      }
      return ret;
    },
    */

    path: function() {
      return "https://demo1.aarcomm.io/demo/" + this.selectedItem.path;
    },
    selected: function() {
      return this.selectedItem ? this.selectedItem.title : "";
    },
    ready() {
      if (this.selected) return true;
      else return true;
    },
    branch() {
      return this.$store.getters.selectedBranch;
    }
  },

  watch: {
    branch() {
      this.$nextTick(async () => {
        await this.refresh();
      });
    },

    reportsByTruck(val) {
      console.log("Reports by Truck Changed");
    }
  },

  async mounted() {
    try {
      this.loadReports();
    } catch (err) {
      console.log(err);
    } finally {
      console.log(this.reportData);
    }
  },
  methods: {
    handleRangeUpdated(item) {
      console.log(item);

      //* Hack: Manually Set the Start and end. v-model should work, but isnt!
      console.log(item.start.getTime(), item.end.getTime());
      this.datePicker.range.start = item.start.getTime();
      this.datePicker.range.end = item.end.getTime();

      console.log(this.datePicker.range);
    },
    handleViewClick(item) {
      try {
        console.log(item);
        // TODO: Display a List of Reports for a Single Truck
        let id = item.device;

        this.$router.push({
          path: "/reports/list/truck",
          query: {
            device: id,
            start: this.datePicker.range.start - 1 + 1,
            end: this.datePicker.range.end - 1 + 1
          }
        });
      } catch (err) {
        console.log(err);
      }
    },
    getReportsByTruck() {
      // Map Report Groups into Elements
      let ret = [];
      for (var truck of this.devices) {
        if (this.reportGroups[truck.name]) {
          let count = this.reportGroups[truck.name].jobReports
            ? this.reportGroups[truck.name].jobReports.length
            : 0;
          if (count > 0) {
            let first = this.getFirst(this.reportGroups[truck.name].jobReports);
            let last = this.getLast(this.reportGroups[truck.name].jobReports);
            ret.push({
              truck: truck.name,
              device: truck.device,
              reports: count,
              _item: this.reportGroups[truck.name],
              first: first,
              last: last
            });
          }
        }
      }
      return ret;
    },
    showInRouter(item) {
      let params = {
        id: item.id || item.ID
        //title: item.title,
        //path: item.path,
      };

      //this.$router.push({name:"Report View",query:{id:item.ID}});
      this.$router.push({ path: "/reports/view", query: params });
    },
    getFirst(jobReports) {
      return jobReports ? jobReports[0] : undefined;
    },
    getLast(jobReports) {
      return jobReports ? jobReports.slice(-1)[0] : undefined;
    },
    async refresh() {
      try {
        console.log("Refreshing");
        await this.loadReports();
      } catch (err) {
        console.log(err);
      }
    },
    toLocalTime(time) {
      var myTime;
      try {
        myTime = this.$moment.duration(Date.now() - new Date(time)).humanize();
        myTime += " ago";
      } catch (e) {
        console.log(e);
        if (time) myTime = this.time.toString();
      } finally {
        return myTime;
      }
    },

    async loadReports() {
      try {
        this.loading = true;
        this.branchInfo = this.$app.myBranch;

        let params = {
          start: this.datePicker.range.start,
          end: this.datePicker.range.end
        };
        let res = await this.$app.loadReports(params); //this.$api.getReports(params);
        if (res) {
          console.log(res);
          this.reportData = res.map((item, id) => {
            return { ...item };
          });

          if (this.reportData.length > 0) {
            //this.datePicker.start = new Date(this.reportData[0].timestamp).getTime();
            //this.datePicker.end = new Date(this.reportData[this.reportData.length-1].timestamp).getTime();
          }

          // TODO: Create a Collection of Reports that are Grouped by Date and by device

          let trucks = this.$app.myDevices;
          this.devices = trucks;
          console.log(trucks);
          for (var truck of trucks) {
            if (!this.reportGroups[truck.name]) {
              this.reportGroups[truck.name] = {
                deviceInfo: { ...truck }
              }; // Init the class
            } else {
              // Update the device Info
              this.reportGroups[truck.name].deviceInfo = truck;
            }
          }

          // Now for each device in our group, sort reports by truck or device
          let keys = Object.keys(this.reportGroups);
          for (var key of keys) {
            this.reportGroups[key].jobReports = this.reportData.filter(
              item =>
                item.truck === key ||
                item.device === this.reportGroups[key].deviceInfo.device
            );
            // Now Sort by Date
            if (this.reportGroups[key].jobReports) {
              this.reportGroups[key].jobReports.sort(
                (a, b) => a.timestamp - b.timestamp
              );
            }
          }

          console.log(this.reportGroups);

          this.reportsByTruck = this.getReportsByTruck();

          console.log(this.reportsByTruck);

          // Next, we need to group Elements by date.
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    /** Helper function to show Report in the Default Display Tab  */
    showInTab(item) {
      // TODO: This will be deprectated

      this.activeTab = 1;
    },
    /** Helper Display Report Viewer as a new "Page" within the app
     * Preferred Method, as it allows user to use navigation buttons
     */
    showInRouter(item) {
      //this.$router.push({path:"/"+ item.path,params:{id:item.id},query:{id:item.id}})
      //this.$router.push({name:"Report View",params:{id:item.id},query:{id:item.id}});

      let params = {
        id: item.id || item.ID
        //title: item.title,
        //path: item.path,
      };

      //this.$router.push({name:"Report View",query:{id:item.ID}});
      this.$router.push({ path: "/reports/view", query: params });
    },
    showReport(item) {
      //alert("You Clicked Report ID: " + item.path);
      //this.selected = item.title;
      this.selectedItem = item;
      this.showInRouter(item);
      //this.showInTab(item);

      /** Get The Report by ID and Display in new View / Tab */
    },
    color(value) {
      let $color;
      if (value <= 25) {
        $color = "info";
      } else if (value > 25 && value <= 50) {
        $color = "success";
      } else if (value > 50 && value <= 75) {
        $color = "warning";
      } else if (value > 75 && value <= 100) {
        $color = "danger";
      }
      return $color;
    }
  }
};
</script>
